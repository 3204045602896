export default {
  accept: (state, accepted) => {
    if (accepted === undefined || accepted === null) {
      return;
    }
    state.accepted = accepted;
    state.showCookies = false;
    localStorage.setItem("cookies_accepted", accepted);
  },
};
